<template>
  <b-card title="Create Inventory">
    <b-modal v-model="loading" centered no-close-on-backdrop hide-footer hide-header>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Processing Inventory</p>
      </div>
    </b-modal>
    <b-col cols="12">

      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="">
            <b-form-group>
              <span
                >DO Number
                <span style="color:red"
                  >(*)</span
                ></span
              ><br />
              <b-row>
                <b-col md="9" cols="6">
                  <b-form-input
                    v-model="form.sapDo"
                    type="text"
                    maxlength="10"
                    placeholder="Enter Do Number"
                  ></b-form-input>
                </b-col>
                <b-col md="3" cols="6">
                  <b-button
                  @click="doCheck()"
                  variant="primary"
                  class="mr-1"
                  >Check</b-button>
                </b-col>
              </b-row>   
            </b-form-group>
            <b-form-group>
              <span
                >Item Model
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-row>
                <b-col cols="9">
                <v-select
                  class="style-chooser"
                  :disabled="disableItem"
                  v-model="form.itemModel"
                  :options="searchItems"
                  :clearSearchOnSelect="true"
                  @input="handleSelectChange"
                >
                </v-select>
                </b-col>
              </b-row>
              <b-col cols="3">
              </b-col>

            </b-form-group>
            <b-form-group>
              <span
                >Serial
                <span style="color:red"
                  >(*)</span
                ></span
              ><br/>
              <b-row>
                <b-col md="9" cols="6">
                  <b-form-input
                  maxlength="8"
                  v-model="form.serial"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Serial"
                ></b-form-input>
                </b-col>
                <b-col md="3" cols="6">
                  <b-button
                  @click="doCheckSerial()"
                  variant="primary"
                  class="mr-1"
                  >Check</b-button>
                </b-col>
              </b-row>
              
            </b-form-group>
            <b-form-group>
              <span
                >Barcode</span
              >
              <b-row>
                <b-col cols="9">
                  <b-form-input
                  disabled
                  maxlength="14"
                  v-model="form.barcode"
                  style="margin-top:5px !important;"
                  type="text"
                ></b-form-input>
                </b-col>
              </b-row>
              
            </b-form-group>
            <br>
            <br>
            <!-- <b-form-group>
              <span
                >Line Item
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="6"
                v-model="form.lineitem"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Enter Line Item"
              ></b-form-input>
            </b-form-group> -->

            <b-button
              v-if="permission.add"
              @click="addSaleBtn"
              type="submit"
              variant="success"
              class="mr-1"
              :disabled="disableButton"
              >Add Inventory</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "@/axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import lodash from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { sleep, userAccess } from "@/utils/utils.js";
// import { isRaw } from "@vue/composition-api";

export default {
  components: {
    "date-picker": DatePicker,
    "v-select": vSelect,
  },
  data() {
    return {
      //form to save data
      form: {
        sapDo: "",
        serial: "",
        barcode: "",
        lineitem: "",
        itemModel : "",
      },
      searchItems: [],
      materialId:'',
      dataDO:'',
      //button disable
      disableButton: true,
      disableItem: true,
      loading : false,
      selectedModel: "",
      selectedItemModel: {},
      idMaterials: [],
    };
  },
  created(){
    document.title = "Manual Create | RSP";
  },
  mounted() {
    let now = new Date();
    this.today = Number.parseInt(String(now.getDate()).padStart(2, "0"));

    //temporary get api customer from axios
    this.$store.dispatch("dealer/getAllDealers");

    //temporary get current user
    this.$store.dispatch("auth/fetchUser");
  },
  methods: {
    ...mapActions({
      getDeliveryOrderByDoNumber: "delivery/getDeliveryOrderByDoNumber",
      getItems: "item/getItems",
      checkStock: "sendSAP/checkStock",
    }),
    doCheck(){
      if(this.form.sapDo){
        this.getDeliveryOrderByDoNumber(this.form.sapDo).then((response) => {
          if(response.length > 0){
            this.dataDO = response[0]
            this.getItemDetail()
            this.disableItem = false
            this.$bvToast.toast("DO is Available", {
            title: "Success",
            variant: "success",
            solid: true,
            })
          }
          else{
            this.disableItem = true
            this.searchItems = []
            this.form.itemModel = ''
            this.$bvToast.toast("DO is not found", {
            title: "Failed",
            variant: "danger",
            solid: true,
            })
          }
        })  
      }
      else{
        this.disableItem = true
        this.searchItems = []
        this.form.itemModel = ''
        this.$bvToast.toast("Please input Do Number", {
            title: "Failed",
            variant: "danger",
            solid: true,
            })
      }
    },
    async doCheckSerial(){
      if(this.form.serial.length === 8){
        let poscode = ""

        if(!this.form.itemModel){
          this.$bvToast.toast("Please Select Item Model", {
            title: "Failed",
            variant: "danger",
            solid: true,
            })
        }
        
        await axios.get(`sales-and-purchase/drcc/postal`, { params: { model : this.selectedModel, entry : 1, page : 1 } }).then((response) => {
          poscode = response.data.data.length!=0 ? response.data.data[0].postal : ""
          if (!poscode) {
            this.$bvToast.toast("This Item Model does not yet have a POS code. Please add the POS code for this Item Model first ", {
            title: "Failed",
            variant: "danger",
            solid: true,
            })
            this.form.barcode = ""
            this.disableButton = true;
          }
          else{
            this.form.barcode = poscode + this.form.serial
            this.disableButton = false;
          }
          return response.data;
        }).catch((e) => {
          this.form.barcode = ""
          console.log(e);
        })

      }
      else {
        this.form.barcode = ""
        this.disableButton = true
        this.$bvToast.toast("Please insert 8 digit serial no. If you only have 7 digit serial no, please add number 9 as the last digit", {
            title: "Failed",
            variant: "danger",
            solid: true,
        })
      }
    },
    handleSelectChange(selectedItem) {
      this.selectedItemModel = selectedItem
      // selectedItem berisi nilai yang dipilih
      // Dapatkan indeks dari selectedItem di dalam searchItems
      const selectedIndex = this.idMaterials.findIndex(item => item === this.selectedItemModel._id);
      
      // Lakukan sesuatu dengan indeks yang disimpan
      this.form.lineitem = selectedIndex + 1
      this.selectedModel = this.form.itemModel.itemModel
    },
    async getItemDetail() {
      try {
        var idList = this.dataDO.DO_details.map((x) => {
          return x.material;
        });

        var list = idList.join("|");
        this.idMaterials = idList
        const response = await axios.get("sales-and-purchase/drcc/items/multi?idList=" + list);

        this.searchItems = response.data.data.map((item) => {
          return {
            label: `${item.itemModel}`,
            ...item,
          };
        });

      } catch (error) {
        console.error("Error fetching items:", error);
      }
    },
    addSaleBtn() {
      //ini waktu klik confirm purchase dimana akan kirim PO ke SAP. dengan sebelumnya akan check stock dulu per item
      //for handling dealer info
      this.disableButton = true;
      this.loading = true;

      if (
        this.form.sapDo === "" ||
        this.form.sapDo === null ||
        this.form.sapDo === undefined
      ) {
        this.$bvToast.toast(`Please Input DO Number`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        this.loading = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if (
        this.form.serial === "" ||
        this.form.serial === null ||
        this.form.serial === undefined
      ) {
        this.$bvToast.toast(`Please Input Serial`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        this.loading = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if (
        this.form.barcode === "" ||
        this.form.barcode === null ||
        this.form.barcode === undefined
      ) {
        this.$bvToast.toast(`Please Input Barcode`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        this.loading = false;
        // Prevent Any Saving Data From Run
        return;
      }

      if (
        this.form.itemModel === "" ||
        this.form.itemModel === null ||
        this.form.itemModel === undefined
      ) {
        this.$bvToast.toast(`Please Input Item Model`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        this.loading = false;
        // Prevent Any Saving Data From Run
        return;
      }

      this.form.itemModel = this.selectedModel

      axios.post('sales-and-purchase/drcc/create-manual/inventory', this.form).then((response) => {
        this.disableButton = false;
        this.loading = false;
        this.$bvToast.toast("successfully created inventory", {
        title: "Success",
        variant: "success",
        solid: true,
        })
        this.form.barcode = ''
        this.form.serial = ''
      })
      .catch((e) => {
        this.disableButton = false;
        this.loading = false;
        this.$bvToast.toast(e.response.data.errors[0]? e.response.data.errors[0].msg : e.response.data.message, {
        title: "Failed",
        variant: "danger",
        solid: true,
        })
      })     
    },
  },
  computed: {
    permission() {
      let result = userAccess("Manual Create Inventory", "manualcreate_menu")
      return result
    },
    user() {
      //buat cek informasi akun yang sedang login
      return this.$store.getters["auth/getActiveUser"];
    },
    dealer() {
      return this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
    },
    getDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      //kalau user bukan dealer, return undefined
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"];

      if (tempDealer === null) {
        tempDealer = this.dealerChoice;
      }
      return tempDealer;
    },
  },
};
</script>

<style>
.deliveryUnitNo {
  width: 100px;
}
.cart__area {
  display: flex;
  flex-direction: column;
}

.cart__area-body {
  display: grid;
  grid-template-rows: 100%;
  overflow-x: scroll;
  margin: 10px 0;
}

.cart__area-columns {
  display: grid;
  grid-template-columns: 40% 15% 15% 15% auto;
  column-gap: 15px;
  margin: 0 0 5px 0;
}

.cart__area-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cart__area-line-info {
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
}

.cart__area-line-info span {
  width: 100%;
  margin: 0 5px 0 0;
}

.cart__area-line-info input {
  width: 100px;
}

.cart-area-columns span {
  font-weight: bold;
}

.v-select .vs__dropdown-toggle {
  height: 2.714rem;
  padding: 0;
}

@media only screen and (max-width: 681px) {
  /* .halo {
    background-color: rebeccapurple;
    
  } */
  .cart__area-columns {
    display: none;
  }
}

@media only screen and (min-width: 682px) {
  /* .halo {
    background-color: red;
  } */
  .halo {
    background-color: red;
  }

  .cart__area-columns_mobile {
    display: none;
  }
}
</style>
